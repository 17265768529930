<template>
  <v-card>
    <div class="d-flex">
      <div class="pa-4">
        <ProductTcoCard v-bind:product="productSelected" :showBtn="false" />
      </div>
      <div>
        <v-card-title class="text-center text-h2 primary--text line-height-13">
          {{ $t("tco.perks.blocks.tcoProduct.products.modal.title") }}
        </v-card-title>
        <v-card-text class="primary--text text-body-1">
          {{
            $t("tco.perks.blocks.tcoProduct.products.modal.text", {
              date: maxDate
            })
          }}
        </v-card-text>
      </div>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="cancel()">
        {{ $t("tco.perks.blocks.tcoProduct.products.modal.cancelBtn") }}
      </v-btn>
      <v-btn color="primary" depressed @click="submit()">
        {{ $t("tco.perks.blocks.tcoProduct.products.modal.confirmBtn") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ProductTcoCard from "@/components/cardOro/ProductTcoCard.vue";

export default {
  name: "TcoProductsModalConfirm",
  components: {
    ProductTcoCard
  },
  data() {
    return {
      maxDate: global.config.showProductsByDate.maxDate
    };
  },
  props: {
    productSelected: { type: Object, required: true }
  },
  computed: {},
  methods: {
    submit() {
      this.$emit("submit", true);
    },
    cancel() {
      this.$emit("submit", false);
    }
  },
  mounted() {}
};
</script>
